<script setup lang="ts">
import 'dayjs/locale/fr';
import 'dayjs/locale/en';

// Components
import EpiOFConsumptionGraph from './EpiOFConsumptionGraph.vue';
import dayjs from 'dayjs';
import { useEPI } from '@/app-modules/explore/composables/useEPI';
import { useI18n } from 'vue-i18n';

// Composables
const { t } = useI18n();
const route = useRoute();
const { getConsumptionByOFID } = useEPI();

// Props
const props = defineProps<{
  ofId: number;
  cost: string | null;
  meter?: number;
}>();
const modal = defineModel<boolean>();

// On Load
const { loading, data, error } = useCustomAsyncData({
  promise: () => getConsumptionByOFID({ ofId: props.ofId, meterId: props.meter ?? parseInt(route.params.id as string) }),
  options: {
    immediate: true,
  },
  callback: () => {
    modal.value = true;
  },
});

// Computed
const fabricationOrder = computed(() => epiStore().getFabricationOrder(props.ofId));
const title = computed(() =>
  fabricationOrder.value?.client_fabrication_order_id
    ? fabricationOrder.value.client_fabrication_order_id +
      ' - ' +
      t('analytics.fabrication_order_of') +
      ' ' +
      (data.value?.fabricationOrder.start ? dayjs(data.value?.fabricationOrder.start).locale('fr').format('dddd D MMMM YYYY') : '...')
    : t('analytics.fabrication_order_of') +
      ' ' +
      (data.value?.fabricationOrder.start ? dayjs(data.value?.fabricationOrder.start).locale('fr').format('dddd D MMMM YYYY') : '...'),
);

const powerAverage = computed(() => {
  const power = data.value?.consumption?.reduce((accumulator: any, currentValue: any) => {
    return accumulator + currentValue.power_kw;
  }, 0);
  return power / data.value?.consumption.length;
});

const consumption = computed(() =>
  data.value?.consumption?.reduce((accumulator: any, currentValue: any) => {
    return accumulator + currentValue.consumption_kwh;
  }, 0),
);

const consumptionData = computed(() => {
  return {
    timestamps: data.value?.consumption?.map((item: any) => item.timestamp),
    consumption_kWh: data.value?.consumption?.map((item: any) => item.consumption_kwh),
    power_kW: data.value?.consumption?.map((item: any) => item.power_kw),
  };
});
</script>
<template>
  <ui-modal v-model="modal" :title="title" width="1000">
    <ui-error v-if="error && !loading" :title="$t('error.title')" :message="$t('error.impossible_to_get_fabrication_order')" />
    <div class="grid gap-2">
      <div v-if="data" class="bg-gray-50 p-4 rounded-2 flex">
        <div>
          <p class="text-start">
            <span class="text-gray-400"> {{ $t('global.start') }}: </span>
            {{
              dayjs(data?.fabricationOrder?.start)
                .locale('fr')
                .format(`dddd DD MMMM YYYY ${$t('global.at')} HH:mm`)
            }}
          </p>
          <p class="text-start">
            <span class="text-gray-400"> {{ $t('global.end') }}: </span>
            {{
              dayjs(data?.fabricationOrder.end)
                .locale('fr')
                .format(`dddd DD MMMM YYYY ${$t('global.at')} HH:mm`)
            }}
          </p>
        </div>
        <div class="mx-8 bg-gray-400 w-[1px]" />
        <div>
          <p class="text-start">
            <span class="text-gray-400"> {{ $t('global.average_power') }}: </span>

            {{ Math.round(powerAverage) }} kW
          </p>
          <p class="text-start">
            <span class="text-gray-400"> {{ $t('global.consumption') }}: </span>
            {{ Math.round(consumption) }} kWh
          </p>
        </div>
        <div class="mx-8 bg-gray-400 w-[1px]" />
        <div>
          <p class="text-start">
            <span class="text-gray-400"> {{ $t('global.cost') }}: </span>
            {{ cost ?? '--' }}
          </p>
        </div>
      </div>
      <div class="bg-gray-50 p4 rounded-2 min-h-[50vh] flex items-center justify-center">
        <ui-loader v-if="loading" />
        <EpiOFConsumptionGraph v-else :consumption="consumptionData" />
      </div>
    </div>

    <template #footer>
      <ui-button color="secondary" @click="modal = false">{{ $t('global.close') }}</ui-button>
    </template>
  </ui-modal>
</template>
