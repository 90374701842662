<script setup lang="ts">
import dayjs from 'dayjs';

// Components
import ProductOfDetailsCard from '../components/cards/productOfDetailsCard.vue';
import manageEpiTargetModal from '../components/modal/manageEpiTargetModal.vue';

// Composables
const route = useRoute();
const { getNewEPIType } = useEPI();

// Data
const defineEPITargetModal = ref(false);
const seletedUnit = ref<'cost_by_quantity' | 'epi' | 'co2'>('cost_by_quantity');

// Store
const productService = productStore();

// Computed
const product = computed(() => {
  return productService.currentProduct;
});

const getLastProduction = computed(() => {
  const productionDates: string[] = [];
  product.value?.fabrication_order_products.forEach((fabricationOrdersProducts) => {
    if (fabricationOrdersProducts.fabrication_order?.start) {
      productionDates.push(fabricationOrdersProducts.fabrication_order?.start);
    }
  });
  const lastProductionDate = productionDates.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
  return productionDates.length > 0 ? dayjs(lastProductionDate[0]).locale('fr').format('dddd DD MMMM YYYY') : '--';
});

const getQuantityForLastThreeMonth = computed(() => {
  let quantity: number = 0;
  let meterId: null | number = null;

  product.value?.fabrication_order_products.forEach((fabricationOrdersProducts) => {
    if (fabricationOrdersProducts.fabrication_order?.start) {
      const start = dayjs(fabricationOrdersProducts.fabrication_order?.start);
      if (start.isAfter(dayjs().subtract(3, 'month'))) {
        meterId = fabricationOrdersProducts.fabrication_order?.fabrication_order_epis[0]?.meter_id;
        quantity += fabricationOrdersProducts.fabrication_order.quantity;
      }
    }
  });

  if (meterId === null) return 0;

  return quantity.toFixed(2);
});

const getAverageEPI = computed(() => {
  const epi: number[] = [];
  const cost_by_quantity: number[] = [];
  const co2: number[] = [];
  let quantity: number = 0;

  product.value?.fabrication_order_products.forEach((fabricationOrdersProducts) => {
    const meter = fabricationOrdersProducts.fabrication_order?.fabrication_order_epis[0]?.meter_id;

    const co2Value = computed(() => useCO2().getCo2byKWhByMeterId(meter));

    const fabricationOrderquantity: number = fabricationOrdersProducts.fabrication_order?.quantity;

    if (fabricationOrdersProducts.fabrication_order?.fabrication_order_epis[0]?.epi === 0) {
      return;
    }

    quantity += fabricationOrderquantity;
    epi.push(fabricationOrdersProducts.fabrication_order?.fabrication_order_epis[0]?.epi * fabricationOrderquantity);
    cost_by_quantity.push(
      fabricationOrdersProducts.fabrication_order?.fabrication_order_epis[0]?.cost_by_quantity * fabricationOrderquantity,
    );
    co2.push(fabricationOrdersProducts.fabrication_order?.fabrication_order_epis[0]?.epi * fabricationOrderquantity * co2Value.value);
  });

  return {
    epi: epi.reduce((sum, currentValue) => sum + currentValue, 0) / quantity || 0,
    cost_by_quantity: cost_by_quantity.reduce((sum, currentValue) => sum + currentValue, 0) / quantity || 0,
    co2: co2.reduce((sum, currentValue) => sum + currentValue, 0) / quantity || 0,
  };
});

// Lifecycle
onMounted(async () => {
  await productService.fetchProduct(parseInt(route.params.id as string));
});
</script>
<template>
  <div class="flex flex-col w-full h-full bg-gray-50">
    <ui-header
      :title="`Produit: ${(product?.client_product_id ?? '...') + (product?.label ? ' - ' + product?.label : '')}`"
      back-url="/tracking/products"
    />
    <div class="grid gap-4 p-4 overflow-y-auto">
      <!-- Informations -->
      <ui-card>
        <div class="grid grid-cols-8 gap-y-4 text-sm">
          <!-- Column left -->
          <div class="flex items-center justify-start col-span-2">
            <p class="mr-2 text-gray-800">{{ $t('product.identifier') }}:</p>
            <ui-badge color="blue">{{ product?.client_product_id }}</ui-badge>
          </div>

          <div class="flex items-center justify-start col-span-4">
            <div class="flex items-center mr-2">
              <ui-dropdown
                v-model="seletedUnit"
                size="xs"
                :placeholder="$t('product.epi_average_in')"
                :items="getNewEPIType()"
                no-separation
              />
              <p>:</p>
            </div>
            <p class="text-gray-500">
              {{ getAverageEPI[seletedUnit].toFixed(2) }}
              {{ getNewEPIType().find((el) => el.key === seletedUnit)?.label }} /
              {{ product?.fabrication_order_products[0]?.fabrication_order?.area?.fabrication_order_unit?.symbol ?? 'kg' }}
            </p>
          </div>

          <div class="flex items-center justify-start row-start-2 col-span-2">
            <p class="mr-2 text-gray-800">{{ $t('product.label') }}:</p>
            <p class="text-gray-500">{{ product?.label ?? '--' }}</p>
          </div>

          <!-- Column right -->
          <div class="flex items-center justify-start row-start-3 col-span-2">
            <p class="mr-2 text-gray-800">{{ $t('product.epi_target') }}:</p>
            <div v-if="product?.epi_standard" class="flex items-center">
              <p class="text-gray-500">
                {{ product?.epi_standard }} kWh /
                {{ product?.fabrication_order_products[0]?.fabrication_order?.area?.fabrication_order_unit?.symbol ?? 'kg' }}
              </p>
              <ui-button
                class="lowercase ml-2"
                color="tertiary"
                size="xs"
                left-icon-class="!w-3 !h-3"
                left-icon="Pen"
                @click="defineEPITargetModal = true"
              >
                {{ $t('global.update') }}
              </ui-button>
            </div>
            <ui-button
              v-else
              class="lowercase"
              color="tertiary"
              size="xs"
              left-icon-class="!w-3 !h-3"
              left-icon="Pen"
              @click="defineEPITargetModal = true"
            >
              {{ $t('product.action.define') }}
            </ui-button>
          </div>

          <div class="flex items-center justify-start row-start-2 col-span-4">
            <p class="mr-2 text-gray-800">{{ $t('product.last_production_date') }}:</p>
            <p class="text-gray-500">{{ getLastProduction }}</p>
          </div>

          <div class="flex items-center justify-start row-start-3 col-span-4">
            <p class="mr-2 text-gray-800">{{ $t('product.quantity_last_3_months') }}:</p>
            <p class="text-gray-500">
              {{ getQuantityForLastThreeMonth }}
              {{ product?.fabrication_order_products[0]?.fabrication_order?.area?.fabrication_order_unit?.symbol ?? 'kg' }}
            </p>
          </div>

          <div class="col-span-1 col-start-3 row-start-1 row-end-4 flex items-center justify-center">
            <div class="w-[1px] bg-gray-300 h-full" />
          </div>
        </div>
      </ui-card>

      <product-of-details-card />
    </div>
  </div>

  <!-- Modal -->
  <manage-epi-target-modal v-model="defineEPITargetModal" />
</template>
